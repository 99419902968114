import EventCard from '@/components/event-card'
import Scrollable from '@/components/scrollable'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import useBrowser from '@/hooks/useBrowser'
import { useCachedVisibleMarketingEvents } from '@/hooks/useVisibleMarketingEvents'
import { cls, openNewTab } from '@/utils'
import { useRouter } from 'next/navigation'
import { useCallback, useMemo } from 'react'

export interface HomeEventsProps {
  className?: string
  frameless?: boolean
}

export default function HomeEvents({ className, frameless }: HomeEventsProps) {
  const browser = useBrowser()
  const { isBelowMd } = useBreakpoint('md')

  const { data: marketingEvents } = useCachedVisibleMarketingEvents()

  // first 2 events
  const visibleMarketingEvents = useMemo(() => {
    return marketingEvents.slice(0, 2)
  }, [marketingEvents])

  const router = useRouter()

  const handleEventClick = useCallback(
    (event: haiper.MarketingEvent) => {
      if (event?.redirect_url) {
        openNewTab(event.redirect_url)
        return
      }
      router.push(`/event?evt=${event.event_id}`)
    },
    [router],
  )

  const renderContent = () => {
    return visibleMarketingEvents?.map((event, index) => {
      return (
        <EventCard
          key={[event.title, index].join()}
          hideStatus
          source='home-events'
          // className='z-0 relative w-full h-[169px] min-h-[169px] flex-1 overflow-hidden rounded-lg'
          className='z-0 relative w-full md:w-100 md:min-w-100 h-30 md:h-50 min-h-36 md:min-h-50 flex-1 overflow-hidden rounded-lg'
          aria-label='marketing event'
          data={event}
          onClick={() => handleEventClick(event)}
        />
      )
    })
  }

  if (frameless) {
    if (isBelowMd) {
      return <div className={cls('w-full h-full flex flex-col gap-8 max-w-full')}>{renderContent()}</div>
    }
    return (
      <div className={cls('w-[412px] h-full', className)}>
        <Scrollable className={cls('flex gap-0')}>{renderContent()}</Scrollable>
      </div>
    )
  }

  return (
    <div
      className={cls(
        'flex flex-col md:flex-row gap-4 w-full h-max flex-1',
        browser?.name === 'safari' && 'md:grow-0',
        className,
      )}
    >
      {renderContent()}
    </div>
  )
}
